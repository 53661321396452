.team_section.outer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .member {
        padding: 20px;
        flex: 50%;
        border: 2px solid _palette(accent_light);
        margin: 20px;
        border-radius: 10px;

        p {
            margin: 0;
        }

        img {
            max-width: 150px;
            max-height: 120px;
            margin-bottom: 20px;

        }

    }

    @include breakpoint("<=medium") {
        flex-wrap: wrap;

        .member {
            img {
                max-width: 200px;
            }

            flex: 1 !important;
        }
    }
}