.flex-social-container {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  color: white;
}
.flex-social-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  flex-direction: row;
}
