///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Spotlight */

	.spotlight {
		@include vendor('display', 'flex');
		@include vendor('align-items', 'center');
		margin: 0 0 _size(element-margin) 0;

		.content {
			@include vendor('flex', '1');

			> :last-child {
				margin-bottom: 0;
			}

			header {
				&.major {
					margin: 0 0 _size(element-margin) 0;
				}
			}
		}

		.image {
			display: inline-block;
			margin-left: 4em;
			padding: 0.65em;
			border-radius: 100%;
			border: solid 1px;

			img {
				display: block;
				border-radius: 100%;
				width: 16em;
			}
		}

		@include breakpoint('<=medium') {
			@include vendor('flex-direction', 'column-reverse');
			text-align: center;

			.content {
				@include vendor('flex', '0 1 auto');
				width: 100%;

				header {
					&.major {
						h2 {
							&:after {
								margin-left: auto;
								margin-right: auto;
							}
						}
					}
				}

				.actions {
					@include vendor('justify-content', 'center');
					width: calc(100% + #{_size(element-margin) * 0.5});
				}
			}

			.image {
				@include vendor('flex', '0 1 auto');
				margin-left: 0;
				margin-bottom: _size(element-margin);
			}
		}

		@include breakpoint('<=small') {
			.image {
				padding: 0.35em;

				img {
					width: 12em;
				}
			}
		}
	}

	@mixin color-spotlight($p: null) {
		.spotlight {
			.image {
				border-color: _palette($p, border);
			}
		}
	}

	@include color-spotlight;