.demo_form {
    padding: 0px 30px 30px 30px;
    max-width: 730px;

    form {
        @include color-form(invert);
    }

    .form_inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .demo_phone_email {
            flex-direction: row;
            display: flex;
            width: 100%;

            @include breakpoint("<=small") {
                flex-direction: column;
                width: 100%;

                input:first-child {
                    margin-right: 0px;
                }
            }

            input:first-child {
                margin-right: 10px;
            }
        }
    }

}

.thank_you_section {
    display: none;
    padding: 40px;

    h2 {
        margin: 0;
        line-height: 1.7;
    }

    img {
        max-width: 60px;
        max-height: 60px;
        object-fit: contain;
        padding-inline-end: 20px;
    }
}