///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Nav */

#nav {
  @include vendor("transition",
    ("border-top-left-radius #{_duration(transition)} ease",
      "border-top-right-radius #{_duration(transition)} ease",
      "padding #{_duration(transition)} ease"
    ));
  position: absolute;
  z-index: _misc(z-index-base);
  width: 100%;
  padding: 0.5em 1em;

  border-top-left-radius: _size(border-radius-main);
  border-top-right-radius: _size(border-radius-main);
  cursor: default;

  .logo {
    height: 32px;
    display: block;
    margin: 11px 2px 0px 2px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;

    li {
      @include vendor("transition", ("margin #{_duration(transition)} ease"));

      display: inline-flex;
      margin: 0 0.35em;
      padding: 0;
      flex-direction: column;
      align-items: center;

      a {
        @include vendor("transition",
          ("font-size #{_duration(transition)} ease"));
        display: inline-block;
        height: 2.25em;
        line-height: 2.25em;
        padding: 0 1.25em;
        border: 0;
        border-radius: _size(border-radius);
        box-shadow: inset 0 0 0 1px transparent;
        color: _palette(invert, fg-bold);
      }

      &.current {
        font-weight: bold;
      }

      &.current::after {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: 0;
        background-color: _palette(accent);

      }
    }

    a,
    img {
      border: 0;
      outline: none;
    }

    .button.primary {
      background-color: _palette(accent_secondary);
      border-color: _palette(accent_secondary);
    }

  }

  &.alt {

    @include color-typography(invert);

    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0.5em 1em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: _misc(z-index-base);
    box-shadow: 0px 0px 8px 0px _palette(shadow);

    a {
      color: _palette(fg-bold);
    }

  }

  @include breakpoint("<=small") {

    max-width: 100%;
    padding: 1em 2px 1em 2px;
    flex-grow: 1;
    padding: 10px;
    margin: 0em;
    font-size: large;
    width: 100%;

    .flex-container {
      ul {
        display: none;
        padding: 0em;
        margin: 0em;
        font-size: large;

        a {
          padding: 0px 2px;
        }
      }
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.navPanelToggle {
  display: none;

  @include breakpoint("<=small") {
    display: inline-flex;
    position: absolute;
    width: 100%;
    z-index: _misc(z-index-base)+ 1;
    justify-content: flex-end;
    padding: 20px;
    color: _palette(invert, fg-bold);

    cursor: pointer;

    a {
      border: none;
      outline: none;

      box-shadow: none;

    }
  }

  &::after {
    content: '\2630';
    line-height: inherit;
    margin: 0 0 0 10px;
    float: right;
  }

  &.alt {

    color: _palette(fg-bold);
    position: fixed;
    top: 0;
    padding: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: _misc(z-index-base);
  }
}