.testimonial.outer {
    // color: _palette(invert, bg);
    @include color-typography(invert);
    background-color: _palette(accent_secondary);

    .testimonial-carousel {

        width: 100%;

        .flickity-button {
            min-width: 0;
        }


        .testimonial-carousel-cell {
            width: 100%;
            padding: 40px 80px;


            .client_image {
                img {
                    max-width: 190px;
                }
            }

            .client_desc {
                p {
                    margin: 0;
                }
            }
        }
    }
}