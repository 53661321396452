.video_detail.outer {
  @include breakpoint("<=medium") {

    .content {
      flex-direction: column-reverse;

      .background {
        max-width: 100% !important;
      }

      .inner_content {
        max-width: 100% !important;
        padding: 0px 30px !important;
      }

      padding: 0 !important;
      align-items: center !important;
      text-align: center !important;
    }
  }

  .content {
    display: flex;
    text-align: right;
    justify-content: space-between;
    color: _palette(accent);
    width: 100%;
    align-items: center;

    .background {
      max-width: 55%;
      float: left;
    }

    .banner-second-half {
      flex: 1;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: right;
      justify-content: flex-end;

      .inner_content {
        max-width: 100%;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}