.hero_banner.outer {
  @include breakpoint("<=medium") {
    padding: 30px 0 !important;

    .content {
      flex-direction: column-reverse;
      padding: 0 !important;
      align-items: center !important;
      text-align: center !important;

      .background {
        max-width: 100% !important;
      }

      .inner_content {
        max-width: 100% !important;

      }

      .banner-second-half {
        max-width: 100% !important;
      }

    }
  }

  .content {
    display: flex;
    text-align: right;
    justify-content: space-between;
    color: _palette(accent);
    width: 100%;
    align-items: center;

    .background {
      max-width: 55%;
      float: left;

      img {
        float: left;
        max-width: 100%;
      }
    }

    .banner-second-half {
      flex: 1;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: right;
      justify-content: flex-end;
      max-width: 45%;

      .inner_content {
        max-width: 100%;
        padding-right: 5%;

        .head_text {
          @include color-typography(purple);
        }

        .sub_text {

          h2 {
            margin: 0 !important;
          }

          #teach--test--track,
          #go-digital {
            color: _palette(accent) !important;
            margin: 0 !important;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            strong {
              color: _palette(accent) !important;

            }
          }
        }

        .pre_slider {
          color: _palette(accent_secondary) !important;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          strong {
            color: _palette(accent_secondary) !important;
            margin: 0 !important;
          }
        }

        .hero-carousel {
          font-weight: bold;
          font-size: larger;

          span {
            color: _palette(accent_secondary) !important;
          }
        }

        .button.primary {
          margin: 20px 0;
        }

      }
    }
  }
}