///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

	.icon {
		@include icon;
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out'
		));
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}

		&.major {
			border: solid 1px;
			display: inline-block;
			border-radius: 100%;
			padding: 0.65em;
			margin: 0 0 _size(element-margin) 0;
			cursor: default;

			&:before {
				display: inline-block;
				font-size: 6.25rem;
				width: 2.25em;
				height: 2.25em;
				line-height: 2.2em;
				border-radius: 100%;
				border: solid 1px;
				text-align: center;
			}
		}

		&.alt {
			display: inline-block;
			border: solid 1px;
			border-radius: 100%;

			&:before {
				display: block;
				font-size: 1.25em;
				width: 2em;
				height: 2em;
				text-align: center;
				line-height: 2em;
			}
		}

		&.style1 {
			color: _palette(accent1);
		}

		&.style2 {
			color: _palette(accent2);
		}

		&.style3 {
			color: _palette(accent3);
		}

		&.style4 {
			color: _palette(accent4);
		}

		&.style5 {
			color: _palette(accent5);
		}

		@include breakpoint('<=xlarge') {
			&.major {
				&:before {
					font-size: 5.5rem;
				}
			}
		}

		@include breakpoint('<=large') {
			&.major {
				&:before {
					font-size: 4.75rem;
				}
			}
		}

		@include breakpoint('<=small') {
			&.major {
				margin: 0 0 (_size(element-margin) * 0.75) 0;
				padding: 0.35em;

				&:before {
					font-size: 3.5rem;
				}
			}
		}
	}

	@mixin color-icon($p: null) {
		.icon {
			&.major {
				border-color: _palette($p, border);

				&:before {
					border-color: _palette($p, border);
				}
			}

			&.alt {
 				border-color: _palette($p, border);
 				color: _palette($p, fg-bold);

				&:hover {
					background-color: _palette($p, border-bg);
				}

				&:active {
					background-color: _palette($p, border2-bg);
				}
 			}
		}
	}

	@include color-icon;