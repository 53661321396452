///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

#footer {
  @include vendor("display", "flex");
  @include vendor("flex-wrap", "wrap");
  padding: 2em 5em 1em 5em;
  //   width: calc(100% + #{_size(element-margin)});
  //   margin: 0 0 (_size(element-margin) * 1.5) (_size(element-margin) * -1);
  @include color-typography(invert);
  background-color: _palette(fg-bold);
  // color: _palette(invert, bg);

  >* {
    width: calc(50% - #{_size(element-margin)});
    margin-left: _size(element-margin);
  }

  .copyright {
    width: 100%;
    margin: (_size(element-margin) * 1.25) 0 _size(element-margin) 0;
    font-size: 0.8em;
    text-align: center;
  }

  @include breakpoint("<=large") {
    @include padding(4em, 4em);
  }

  @include breakpoint("<=medium") {
    @include padding(4em, 3em);
    text-align: center;
    display: block;
    width: 100%;

    >* {
      width: 100%;
      margin-left: 0;
      margin-bottom: (_size(element-margin) * 1.5);
    }

    .copyright {
      text-align: left;
    }
  }

  @include breakpoint("<=small") {
    @include padding(3em, 2em);
  }

  @include breakpoint("<=xsmall") {
    padding: 3em 1.5em 1em 1.5em;
  }
}