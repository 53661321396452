///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

// MSIE: Required for IEMobile.
@-ms-viewport {
  width: device-width;
}

// MSIE: Prevents scrollbar from overlapping content.
body {
  -ms-overflow-style: scrollbar;
}

// Ensures page width is always >=320px.
@include breakpoint("<=xsmall") {

  html,
  body {
    min-width: 320px;
  }
}

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {


  background-size: auto, auto, 100% auto;
  background-position: top center, top center, top center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-attachment: fixed, fixed, fixed, scroll;

  // video {
  //   -moz-object-fit: cover;
  //   -webkit-object-fit: cover;
  //   -ms-object-fit: cover;
  //   object-fit: cover;
  //   -moz-object-position: center;
  //   -webkit-object-position: center;
  //   -ms-object-position: center;
  //   object-position: center;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   z-index: -1;
  // }

  // Stops initial animations until page loads.

  &.is-preload {

    *,
    *:before,
    *:after {
      @include vendor("animation", "important");
      @include vendor("transition", "important");
    }
  }
}