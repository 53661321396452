.pricing.outer {
    padding: 20px;

    .flex-pricing-container {
        display: flex;
        outline: none;
        justify-content: center;
    }

    @include breakpoint("<=large") {
        padding: 0.5em !important;

        .flex-pricing-container {
            flex-wrap: wrap;

            .flex-pricing-column {
                width: 40% !important;
            }
        }
    }

    @include breakpoint("<=small") {

        .flex-pricing-container {
            .flex-pricing-column {
                width: 100% !important;
            }
        }
    }

    .flex-pricing-column {
        display: flex;
        padding: 20px 0px;
        width: 25%;
        margin: 10px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        outline: 0;
        border: 1px solid _palette(accent_light);

        h3 {
            font-weight: bold;
        }

        .plan_price {
            @include color-typography(invert);
            background-color: _palette(fg-bold);
            width: 100%;
            padding: 20px;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            strong,
            p {
                margin: 0px;
            }
        }

        ul {
            list-style: none;
            padding: 10px;
            margin: 0;
            width: 100%;

            li {
                border-bottom: solid 1px _palette(divider);
                padding: 10px;
                font-size: medium;

                &:last-child {
                    border-bottom: 0;
                }

                &:hover {
                    color: _palette(invert, bg);
                    background-color: _palette(accent) !important;
                    font-weight: bold;
                }
            }
        }
    }

}