.text_template {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong,
    p {
        margin: 10px;
    }
}