.staggered_post_template {
    >.content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
        justify-content: center;

        header {
            width: 70%;
            text-align: center;
        }

        .inner {
            max-width: 1136px;

            .posts {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                margin: 0 0 32px 0;
                justify-content: center;

                @include breakpoint('<=medium') {
                    margin-bottom: 0;
                }

                .articles {
                    padding: 0 20px;
                    width: calc(33.33333% + -8px);

                    .article_image {
                        display: inline-block;
                        overflow: hidden;

                        img {
                            width: 100%;
                            transition: transform 0.2s ease-in-out;

                            &:hover {
                                transform: scale(1.05);
                            }
                        }

                    }

                    article {
                        margin: 0 0 48px 0;
                        background-color: _palette(invert, bg);

                        .content {
                            padding: 40px;
                            text-align: left;

                            h3 {
                                font-weight: bold;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }



                    @include breakpoint('<=medium') {
                        width: calc(60%);
                        padding: 0 40px;

                        article {
                            .content {
                                padding: 20px;
                                text-align: center;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }

                            &:last-child {
                                margin-bottom: 48px;
                            }
                        }

                    }

                    @include breakpoint('<=small') {
                        width: calc(100% + -8px);
                    }
                }
            }
        }
    }
}