.detail_page #wrapper #main.jd_ads {
  padding: 75px 15px 5px 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  .jd_header {
    margin: 5px 0px 17px 0px;

    h1 {
      font-weight: bold;
      font-size: 20px;
      text-align: left;
      color: #666666;
    }

    .sub_head {
      font-size: 15px;
      text-align: left;
      color: #444444;

      .icon {
        margin-left: 3px;
        margin-right: 7px;
      }
    }
  }

  .whatsapp_cta {
    .round_button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      height: 70px;
      background: rgb(255, 248, 226);
      background: linear-gradient(
        90deg,
        rgba(255, 248, 226, 1) 0%,
        rgba(255, 248, 226, 1) 70%,
        rgba(255, 209, 53, 1) 70%,
        rgba(255, 209, 53, 1) 100%
      );
      border-radius: 30px;
      border-color: #ffd135;
      border-style: solid;
      border-width: 1px;
      padding: 9px 10px 9px 30px;
      margin-bottom: 10px;

      .cta_text {
        width: 70%;
        text-align: left;
        font-size: 17px;
        color: #00489e;

        .sub_head {
          color: #4d8dc7;
          font-size: 14px;
        }
      }

      .cta_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: #00193e;
      }
    }

    .cta_messages {
      text-align: center;
      font-size: 12px;
      font-style: italic;
      color: #777;
    }

    .star_messages {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .message_item {
        display: inline;
        color: #707070;
        font-size: 15px;

        .star {
          color: #ffd135;
          margin-right: 5px;
        }
      }
    }
  }

  .ad_job_detail {
    margin-top: 20px;

    .heading {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 15px;
      background-color: #f5f5f5;
      padding: 10px 10px 10px 30px;
      border-radius: 5px;
    }
  }

  .ad_sticky_button {
    color: #999;
    font-size: 0.75em;
    margin-left: -15px;

    .button_container {
      background-color: #ffd135;
      padding: 10px;
      color: #0c499b;
      display: flex;
      justify-content: center;
      align-items: center;

      .cta_text {
        color: #999;
        margin-left: 7px;
        font-size: 20px;
        color: #0c499b;
      }
    }
  }
}
