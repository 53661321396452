.detail_page #wrapper #main .language_page_container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .mobile_logo {
        img {
            width: 190px;
        }

        padding: 20px 5px;
    }

    .languages {
        input {
            visibility: hidden;
        }

        label {
            cursor: pointer;
            display: block;
        }

        input+label {
            width: 300px;
            text-align: center;
            margin-top: 10px;
            padding: 10px 0px;
            background-color: white;
            border-radius: 30px;
            border-style: solid;
            border-width: 1px;
            color: #888888;
            font-weight: 400;
            border-color: #888888;
        }

        input:checked+label {
            width: 300px;
            text-align: center;
            margin-top: 10px;
            padding: 10px 0px;
            background-color: white;
            border-radius: 30px;
            border-style: solid;
            border-width: 1px;
            color: white;
            font-weight: 400;
            background-color: #0c499a;
            border-color: #0c499a;
        }
    }



}