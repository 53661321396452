.black-white {
    @include color-typography(invert);
    background-color: _palette(fg-bold) !important;
}

.white-purple {
    @include color-typography(purple);
    background-color: _palette(invert, bg) !important;
}

.white-cyan {
    @include color-typography(cyan);
    background-color: _palette(invert, bg) !important;
}

.purple-white {
    @include color-typography(invert);
    background-color: _palette(accent_secondary) !important;
}

.purple-cyan {
    @include color-typography(cyan);
    background-color: _palette(accent_secondary) !important;
}

.purple-white {
    @include color-typography(invert);
    background-color: _palette(accent_secondary) !important;
}

.cyan-purple {
    @include color-typography(purple);
    background-color: _palette(accent) !important;
}

.cyan-white {
    @include color-typography(invert);
    background-color: _palette(accent) !important;
}

.cyan_dark-white {
    @include color-typography(invert);
    background-color: _palette(accent_dark) !important;
}