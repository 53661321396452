.static_text {
  display: none;

  @include breakpoint("<=small") {
    display: inline-block;
    background-color: _palette(invert, bg);
    position: fixed;
    width: 100%;
    padding: 20px;
    bottom: 0px;
    animation: slide 0.5s;

    p {
      margin-left: 10px;
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 11.5pt;
    }

    div.button {
      width: 100%;
      animation: shake, shake, shake;
      animation-delay: 1s, 5s, 15s;
      animation-duration: 0.5s;
      height: 4em;
      text-align: center;
      line-height: 4em;
    }
  }
}

.sticky_container {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  justify-content: center;
  margin-bottom: 10px;
}

@keyframes slide {
  0% {
    bottom: -140px;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes shake {
  0% {
    display: none;
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}