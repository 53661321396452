#banner_image {
  width: 100%;

  div {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    object-fit: scale-down;

    img {
      max-width: 100%;
    }
  }
}