.tab_section_3 {
    width: 100%;
    background-color: _palette(invert, fg-bold);

    .content {
        @include color-typography(invert);
        width: 100%;

        text-align: center;

        .header {
            background-color: _palette(accent_secondary);
            padding: 30px;

            p,
            h3 {
                margin: 0;
            }
        }

        .flex-tab-container {
            display: flex;
            outline: none;
            flex-direction: column;

            .tab-headers {
                display: flex;
                outline: none;
                flex-direction: row;
                justify-content: space-around;
                background-color: _palette(accent_secondary);

                .header_text {
                    cursor: pointer;
                    padding: 20px;
                    font-size: 22px;
                    font-weight: 400;
                    color: _palette(invert, fg-bold);

                    @include breakpoint("<=small") {
                        font-size: 13px;
                        // .extra {
                        //     display: none;
                        // }
                    }

                    &.active {
                        background-color: _palette(invert, fg-bold);
                        color: _palette(accent);
                        -webkit-animation: tabTitle 0.5s ease forwards;
                        animation: tabTitle 0.5s ease forwards;
                    }
                }
            }
        }

        .tab-panels {

            // @include vendor("transition",
            //     ("font-size #{_duration(transition)} ease"));
            width: 90%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            padding: 90px 0 64px 0;
            align-self: center;


            @include breakpoint("<=medium") {
                padding: 50px 0 0 0;
                width: 100%;
            }

            @include breakpoint("<=small") {
                padding: 50px 0 0 0;
                width: 80%;
            }

            .sub_text {
                display: none;

                &.active {
                    display: flex;
                    background-color: _palette(invert, fg-bold);
                    justify-content: center;
                    align-items: center;


                    * {
                        flex-shrink: 1;
                        width: 100%;
                    }

                    p {
                        margin: 0;
                        @include color-typography(invert);

                        strong {
                            @include color-typography(invert);

                        }
                    }

                    @include breakpoint("<=medium") {
                        flex-wrap: wrap;

                        .image {
                            order: 1;
                            flex: none !important;
                        }

                        ul {
                            order: 2;
                            width: 40%;
                            flex: none !important;
                            text-align: center !important;
                            margin: 32px 40px;
                        }
                    }

                    @include breakpoint("<=small") {
                        ul {
                            width: 100%;
                            text-align: center !important;

                            &:first-child {

                                margin: 0;
                                padding-top: 32px;
                            }

                            &:last-child {
                                border-top: solid 1px _palette(divider);
                                padding-top: 32px;
                            }
                        }
                    }

                    .image {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 0.6;

                        img {
                            border-radius: 100%;
                            max-width: 500px;

                            @include breakpoint("<=medium") {
                                max-width: 250px;
                            }
                        }
                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        flex: 0.3;

                        &:first-child {
                            text-align: end;

                            li:first-child {
                                border-top: 0;
                                margin-top: 0;
                                padding-top: 0;
                            }
                        }

                        &:last-child {
                            text-align: start;

                            li:first-child {
                                border-top: 0;
                                margin-top: 0;
                                padding-top: 0;
                            }
                        }

                        li {
                            border-top: solid 1px _palette(divider);
                            margin: 32px 0 0 0;
                            padding: 32px 0 0 0;
                        }
                    }

                    h4 {
                        color: _palette(accent) !important;
                        font-weight: bold;
                    }
                }

                -webkit-animation: fadeEffect 1s;
                animation: fadeEffect 1s;
            }
        }

        @-webkit-keyframes tabTitle {
            0% {
                transform: translateY(5px)
            }

            100% {
                transform: translateY(1px)
            }

        }

        @keyframes tabTitle {
            0% {
                transform: translateY(5px)
            }

            100% {
                transform: translateY(1px)
            }

        }

        /* Fade in tabs */
        @-webkit-keyframes fadeEffect {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        @keyframes fadeEffect {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }
}