///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Features */

	.features {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		width: calc(100% + #{_size(element-margin)});
		margin: 0 0 (_size(element-margin) * 1.5) (_size(element-margin) * -1);
		padding: 0;
		list-style: none;

		li {
			width: calc(#{(100% / 3)} - #{_size(element-margin)});
			margin-left: _size(element-margin);
			margin-top: (_size(element-margin) * 1.5);
			padding: 0;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				margin-top: 0;
			}

			> :last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint('<=medium') {
			li {
				width: calc(#{(100% / 2)} - #{_size(element-margin)});

				&:nth-child(3) {
					margin-top: (_size(element-margin) * 1.5);
				}
			}
		}

		@include breakpoint('<=small') {
			width: 100%;
			margin: 0 0 _size(element-margin) 0;

			li {
				width: 100%;
				margin-left: 0;
				margin-top: _size(element-margin);

				&:nth-child(2),
				&:nth-child(3) {
					margin-top: _size(element-margin);
				}
			}
		}
	}