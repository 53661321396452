.detail_page #wrapper #main.refer_page_container {
    padding: 0px 15px 5px 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;

    section {
        padding: 10px;
        margin: 10px;
        max-width: 50%;
        width: 100%;


        @include breakpoint("<=small") {
            max-width: 100%;
        }

        h2,
        h4 {
            background-color: #0c499a;
            color: white;
        }
    }

    .image_section {
        img {
            max-width: 100%;
        }
    }

    .text_section {
        color: black;
        margin: 5px 10px 10px 10px;
        padding: 5px 10px 10px 10px;

        h2 {
            background-color: white;
            color: #0c499a;
            font-weight: bold;
        }

        .detail_section {
            text-align: left;
        }

        a {
            background-color: #0c499a;
            color: white;
        }
    }

    .winner_section {
        background-color: #0c499a;

        .heading {
            background-color: #0c499a;

            .star {
                color: #ffd135;
            }
        }

        td {
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #ffd135;
        }

        tr {
            color: black;
            background-color: white;

            &:hover {
                background-color: #f5f5f5;
                background-color: #0c499a;
                color: white;
            }
        }

    }

    .left_logo {
        img {
            width: 100px;
        }

        @include breakpoint("<=xsmall") {
            left: 0;
            top: 0;
            position: absolute;
        }

        padding: 10px 5px;
        align-self: flex-start;
    }
}

#overlay_tc {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    right: -25%;
    transform: translate(-50%, -50%);
    color: white;
    background: white;
    z-index: 1000;
    background-color: #0c499a;
    color: white;

    h3 {
        color: white;
        font-weight: bold;
        font-size: 23px;
    }

    #tc_text {
        padding: 10px;
    }

    #close_tc {
        float: right;
        margin-right: 5px;
    }
}