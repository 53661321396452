.detail_page #wrapper #main {
  padding: 0em;


  .main {
    padding: 2em 0em 2em 0em;

    section {
      padding: 0em;
    }
  }

  .detail_content {
    text-align: justify;

    h2 {
      text-align: left;
    }

    padding: 0.5em 5em 1em 5em;

    @include breakpoint("<=small") {
      padding: 1em 2.5em;
      color: #444;
      font-size: 1.2em;
    }
  }
}

#apply_button {
  text-align: center;
}

#category_banner_image {
  padding-top: 1.3em;

  div {
    height: 300px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    @include breakpoint("<=large") {
      height: 250px;
    }

    @include breakpoint("<=small") {
      height: 170px;
    }
  }
}