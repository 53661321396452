.flex-social-image-container {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  padding: 0.5em 5em 0em 5em;
  color: white;
  justify-content: space-around;
  padding-bottom: 20px;
}
.flex-social-text-container {
  display: flex;
  flex-wrap: wrap;
  outline: none;
  color: black;
  flex-direction: column;
  text-align: center;
  p {
    margin: 0;
  }
}
.flex-social-link {
  display: flex;
  width: 250px;
  flex-direction: row;
  height: 70px;
  padding: 5px;
  outline: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 10px 0px;

  p {
    padding: 5px 5px 2px 4.4em;
    color: white !important;
    text-align: left;
  }
  @include breakpoint(">=large") {
    width: 250px;
    padding: 1px 1px 1px 0.5em;
    height: 70px;
  }
  @include breakpoint(">=xlarge") {
    width: 300px;
    height: 80px;
  }
}
