.category_icon.outer {
  background-color: _palette(light_grey);
  padding: 30px 0px;

  .content {
    width: 100%;

    p {
      margin: 0;
    }

    .header_text,
    .sub_header_text {
      padding: 10px 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        color: _palette(accent_secondary) !important;
      }

      h3 {
        margin: 0 !important;
      }
    }

    .flex-container {
      outline: none;

      .flickity-button {
        min-width: 0;
      }

      .carousel-cell.is-selected {
        transform: scale(1.2);

        @include breakpoint("<=small") {
          transform: scale(1.1);
        }
      }

      .carousel-cell {
        width: 210px;
        margin: 20px;
        height: 280px;
        padding: 20px 20px 20px 20px;

        outline: none;
        outline: 0;
        background-color: _palette(invert, bg);

        .flex-container-column {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          justify-content: center;

          @include breakpoint("<=small") {
            max-width: 250px;
          }

          h6 {
            font-weight: bold;

            &.location {
              color: _palette(accent);
            }
          }

          .category-image {
            display: block;
            outline: none;
            outline: 0;

            img {
              width: 170px;
              height: 170px;
              object-fit: contain;
            }

            &::after {
              display: block;
              content: "";
              background-color: _palette(accent);
              margin: 15px auto;
              width: 60px;
              height: 3px;

            }
          }


        }
      }
    }
  }
}