///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

#header {
  text-align: center;

  h1 {
    margin: 0 0 (_size(element-margin) * 0.125) 0;
    font-size: 2.25em;
  }

  p {
    font-size: inherit;
    letter-spacing: _font(letter-spacing);
    margin: 0em 0em 10px 0em;
  }

  &.alt {
    .logo {
      display: block;
      margin: 0 auto;
      max-width: 75%;
    }
  }

  @include breakpoint("<=medium") {
    &.alt {
      .header-div {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center, center, top center;
      }
    }
  }

  @include breakpoint("<=small") {
    p {
      letter-spacing: 0;

      br {
        display: none;
      }
    }
  }
}

.header-div {
  color: _palette(invert, fg-bold);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: _palette(invert, fg-bold);
  }

  width: 100%;

  height: 450px;

  position: relative;
}

.header-text {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  padding: 90px;

  @include breakpoint("<=medium") {
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
  }

  @include breakpoint("<=small") {
    position: relative;
    padding: 40px;
  }
}