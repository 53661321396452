.cc-window {
  opacity: 1;

  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.cc-window.cc-invisible {
  opacity: 0;
}

/* only animate ifhas class 'cc-animate' */
.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -moz-transition: transform 1s ease;
  -ms-transition: transform 1s ease;
  -o-transition: transform 1s ease;
  transition: transform 1s ease;
}
.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em);
}
.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em);
}
.cc-animate.cc-revoke.cc-active.cc-top {
  transform: translateY(0);
}
.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0);
}
.cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  /* Initially we don't want any height, and we want the contents to be hidden */
  max-height: 0;
  overflow: hidden;

  /* Set our transitions up. */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}

/* the popup window */
.cc-window,
.cc-revoke {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box; /* exclude padding when dealing with width */

  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px; /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: 1.5em;

  display: flex;
  flex-wrap: nowrap;

  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9998;
}

.cc-revoke {
  z-index: 9999;
}

.cc-window.cc-static {
  position: static;
}

/* 2 basic types of window - floating / banner */
.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em; /* 1em == 16px therefore 24em == 384px */
  flex-direction: column;
}
.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction: row;
}

.cc-revoke {
  padding: 0.5em;
}
.cc-revoke:hover {
  text-decoration: underline;
}

.cc-header {
  font-size: 18px;
  font-weight: bold;
}

/* clickable things */
.cc-btn,
.cc-link,
.cc-close,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  border: none;
  padding: 0.2em;
  text-decoration: underline;
}
.cc-link:hover {
  opacity: 1;
}
.cc-link:active,
.cc-link:visited {
  color: initial;
}

.cc-btn {
  display: block;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
}

.cc-highlight .cc-btn:first-child:hover,
.cc-highlight .cc-btn:first-child:focus {
  background-color: transparent;
  text-decoration: underline;
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;

  /* seeing as this contains text and not an image, the element taller than it is wide (because it is text) */
  /*  - we want it to be a square, because it's acting as an icon */
  /*  - setting the line height normalizes the height */
  line-height: 0.75;
}
.cc-close:hover,
.cc-close:focus {
  opacity: 1;
}

/* This file should contain styles that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}
.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

/**************************************** FLOATING ****************************************/

/* these classes position the floating element */
.cc-top {
  top: 1em;
}
.cc-left {
  left: 1em;
}
.cc-right {
  right: 1em;
}
.cc-bottom {
  bottom: 1em;
}

/* links that are direct decendants should be displayed as block */
.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

/**************************************** BANNER ****************************************/

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0;
}
.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.cc-banner .cc-message {
  display: block;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em;
}

/* COMPLIANCE BOX */

.cc-compliance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cc-floating .cc-compliance > .cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: 0.5em;
}

/* Categories Layout */
.cc-window.cc-type-categories {
  display       : inline-flex;
  flex-direction: column;
  overflow      : visible;
  .form{
    text-align: right;
  }
  .cc-btn {
    margin: 0;
    &.cc-save {
      margin        : 0;
      display       : inline-block;
    }
  }
}
.cc-categories {
  display: inline-flex;
  .cc-category{
    display  : flex;
    max-width: 100%;
    margin   : 0 2px;
    position : relative;
  }
  .cc-btn {
    border-right: none;
    outline     : none;
    input[type=checkbox]{
      float: left;
      height: 26px;
      width: calc( 100% - 22px );
      display: block;
      position: absolute;
      top: 0;
      left: 2px;
      cursor: pointer;
    }
    &:not(.cc-info):not(.cc-save){
      padding-left: 26px;
    }
  }
  .cc-info {
    border-left : none;
    border-right: 2px solid lightgrey;
    padding     : 4px;
    font-variant: all-small-caps;
    &:focus + .cc-tooltip{
      display: block;
    }
  }
  .cc-tooltip{
    display      : none;
    position     : absolute;
    z-index      : 3;
    width        : 190px;
    bottom       : 46px;
    padding      : 8px;
    border       : thin solid lightgrey;
    box-shadow: 1px 1px 4px rgba( 150, 150, 150, .7 );
    &:after{
      content   : "";
      width     : 10px;
      height    : 10px;
      transform : rotate(45deg);
      position  : absolute;
      bottom    : -7px;
      left      : 10px;
      box-shadow: 2px 1px 1px rgba( 200, 200, 200, .5 );
    }
    p {
      margin: 0;
    }
  }
}

@media print {
  .cc-window,
  .cc-revoke {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait),
  screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window {
    &.cc-top {
      top: 0;
    }
    &.cc-bottom {
      bottom: 0;
    }
    &.cc-banner,
    &.cc-floating,
    &.cc-right,
    &.cc-left {
      left: 0;
      right: 0;
    }
    
    &.cc-banner {
      flex-direction: column;
      align-items: unset;
      .cc-compliance {
        flex: 1 1 auto;
      }
      .cc-message {
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
    &.cc-floating {
      max-width: none;
    }
    &.cc-type-categories {
      flex-direction: column;
    }
    .cc-message {
      margin-bottom: 1em;
    }
    .cc-categories{
      flex-direction: column;
      width: 100%;
      margin-right: 8px;
    }
    .cc-category {
      margin: 4px 0;
      .cc-btn:not(.cc-info){
        width: calc( 100% - 16px);
        min-width: 140px;
      }
    }
  } 
}
@media screen and (max-width: 854px) {
  .cc-window.cc-type-categories .cc-btn.cc-save {
    margin: 8px 0;
  }
}
