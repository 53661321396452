#overlay {
  display: none;

  @include breakpoint("<=small") {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0c499a;
    z-index: _misc(z-index-base) + 1;

    cursor: pointer;
    padding: 10px;

    #pop {
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-image: url("/_data/images/uploads/overlay_pop.png");
      background-repeat: no-repeat;
      background-size: contain;

      p {
        margin-bottom: 0;
      }

      #top_head {
        margin-bottom: 0.7em;
      }

      #back_b {
        text-align: center;
      }

      #overlay_text {
        padding: 160px 10px 0px 20px;
        font-size: 14pt;
        display: flex;
        flex-direction: column;

        color: white;
        flex: 1;

        #bottom_b {
          margin-top: auto;

          a {
            font-weight: bold;
            margin-bottom: 10px;
          }
        }

        strong {
          color: #fdcf00;
        }

        .button {
          height: 4em;
          line-height: 4em;
          align-self: flex-end;
        }

        ul {
          list-style-type: none;
          margin: 0 0 1em 0;
          padding-left: 0;

          li::before {
            color: #fdcf00;
            flex: 1;
            content: "\25BA";
            padding-right: 5px;
          }

          li {
            p {
              margin: 0;
            }

            flex-flow: wrap;
          }
        }
      }
    }
  }
}