.call_to_action {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px;


    .button {
        width: 25%;

        @include breakpoint("<=small") {
            width: 80%;
        }
    }
}