@import "libs/vars";
@import "libs/functions";
@import "libs/mixins";
@import "libs/vendor";
@import "libs/breakpoints";
@import "libs/html-grid";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
@import "font-awesome.min.css";
@import "flickity.min.css";
@import "slick.css";

/*
	Stellar by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

@include breakpoints((xlarge: (1281px,
      1680px),
    large: (981px,
      1280px),
    medium: (737px,
      980px),
    small: (481px,
      736px),
    xsmall: (361px,
      480px),
    xxsmall: (null,
      360px)));

// Components

@import "components/cookieconsent";

// Mixins.

@mixin color($p) {
  @include color-typography($p);
  @include color-box($p);
  @include color-button($p);
  // @include color-form($p);
  @include color-icon($p);
  @include color-list($p);
  @include color-section($p);
  @include color-table($p);
  @include color-spotlight($p);
}

// Base.
$baseurl: "/";
@import "base/reset";
@import "base/page";
@import "base/typography";
@import "base/theme";

// Component.

@import "components/row";
@import "components/box";
@import "components/button";
@import "components/cookieconsent";
// @import "components/form";
@import "components/icon";
@import "components/image";
@import "components/list";
@import "components/actions";
@import "components/icons";
@import "components/section";
@import "components/table";
@import "components/features";
@import "components/statistics";
@import "components/spotlight";
@import "components/overlay";
@import "components/drawer";
@import "components/form";

// Layout.

@import "layout/header";
@import "layout/nav";
@import "layout/main";
@import "layout/footer";
@import "layout/wrapper";
@import "layout/category";
@import "layout/detail_page";
@import "layout/3_banner_template";
@import "layout/two_column_section";
@import "layout/default_section";
@import "layout/social_link_template";
@import "layout/page_default_template";
@import "layout/sticky_text_template";
@import "layout/ad_page";
@import "layout/refer_page";
@import "layout/mobile_layout";
@import "layout/3_tab_template";
@import "layout/staggered_post_template";
@import "layout/hero_banner_template";
@import "layout/form_template";
@import "layout/testimonial_template";
@import "layout/team_template";
@import "layout/call_to_action";
@import "layout/video_template";
@import "layout/video_detail_template";
@import "layout/text_template";
@import "layout/pricing_template";