.banner_3.outer {
  padding: 2em !important;
  width: 100%;

  background-color: _palette(light_grey);

  @include breakpoint("<=small") {
    padding: 0.5em !important;
  }
}

.flex-banner-container {
  display: flex;
  outline: none;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-banner-column {
  display: flex;
  padding: 30px;
  width: calc(30% - #{_size(element-margin)});
  margin: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  text-align: center;
  outline: 0;
  min-width: 300px;
  align-self: center;

  img {
    margin-bottom: 15px;
    max-width: 120px;
  }

  h3 {
    font-weight: bold;
    flex: 0.5;
  }

  div {
    align-items: center;
    flex: 1;
  }
}