///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

	.image {
		border-radius: _size(border-radius);
		border: 0;
		display: inline-block;
		position: relative;

		img {
			border-radius: _size(border-radius);
			display: block;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 1.5em 1em 0;
			top: 0.25em;
		}

		&.right {
			float: right;
			margin: 0 0 1em 1.5em;
			top: 0.25em;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}