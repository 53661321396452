#drawer {
    display: none;

    @include breakpoint("<=small") {
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        background-color: transparentize($color: _palette(invert, fg-bold), $amount: 0.5);
        z-index: _misc(z-index-base) + 2;
        cursor: pointer;
        animation: slide_out_right 1s forwards;
        animation: slide_in_right 1s forwards;

        .content {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: _palette(accent);
            float: right;
            padding: 40px 20px;


            li a {
                color: _palette(invert, fg-bold) !important;
                font-weight: 700;
                padding: 10px 0px;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    margin: 10px 0px;
                    background-color: transparentize($color: _palette(bg-alt), $amount: 0.5);
                }
            }

            .close {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px 20px;
                font-size: x-large;
                color: _palette(bg-alt);
                font-weight: 800;
            }

            ul {
                list-style: none;
                padding: 0px;
            }

        }



    }
}

@keyframes slide_in_right {
    0% {
        right: -100%;
    }

    100% {
        right: 0%;
    }
}

@keyframes slide_out_right {
    0% {
        right: 0%;
    }

    100% {
        right: 100%;
    }
}