.two_column {
  display: flex;
  flex-wrap: wrap;
  outline: none;

  #c1_content {
    text-align: center;
    flex: 50%;

    h2 {
      margin: 0;
    }

    padding: 20px;
    margin: auto;

    @include breakpoint("<=small") {
      flex: 100%;
    }
  }

  #c2_content {
    flex: 50%;
    height: 100%;

    img {
      width: 100%;
    }

    @include breakpoint("<=small") {
      flex: 100%;
    }
  }

  a {
    margin-bottom: 20px;
  }
}

.slidingVertical {
  display: flex;
  height: 60px;
  justify-content: center;

  span {
    display: flex;
  }
}

.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}

.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}

.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@-moz-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -moz-transform: translateY(-10px);
  }

  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -moz-transform: translateY(10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -ms-transform: translateY(-10px);
  }

  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -ms-transform: translateY(10px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}