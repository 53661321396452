///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Row */

	.row {
		@include html-grid(1.5em);

		@include breakpoint('<=xlarge') {
			@include html-grid(1.5em, 'xlarge');
		}

		@include breakpoint('<=large') {
			@include html-grid(1.5em, 'large');
		}

		@include breakpoint('<=medium') {
			@include html-grid(1.5em, 'medium');
		}

		@include breakpoint('<=small') {
			@include html-grid(1em, 'small');
		}

		@include breakpoint('<=xsmall') {
			@include html-grid(1.25em, 'xsmall');
		}
	}